import { Outlet, useParams } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useState, useEffect } from 'react'
import Header from '../components/Header'
import LeftBar from '../components/LeftBar'
import Loader from '../components/Loader'
import useAuth from '../hooks/useAuth'
import InfoMessage from '../components/InfoMessage'
import AppUpdate from '../components/AppUpdate'
import Modal from '../components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

function MainLayout() {
  const { id, clientId, claimId, sessionId } = useParams()
  const axios = useAxiosPrivate()
  const {
    auth,
    setShowLoader,
    showLoader,
    showInfo,
    showUpdate,
    createInfo,
    can,
  } = useAuth()
  const [collapsed, setCollapsed] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [openQueryModal, setOpenQueryModal] = useState(false)
  const [clientFound, setClientFound] = useState(true)
  const [query, setQuery] = useState({})
  const [sessionData, setSessionData] = useState({})
  const [clientData, setClientData] = useState({
    name: '',
    reference: 'WBA-1000',
  })

  const toggleSidebar = () => {
    setCollapsed(!collapsed)
  }

  const getClientById = async () => {
    try {
      let response = {}
      if (claimId) {
        response = await axios.get(`/clients/${clientId}/claims/${claimId}`)
      } else {
        response = await axios.get(`/clients/${id ?? clientId}`)
      }

      console.log(response.data.error)
      if (!response.data.error) {
        await setClientData(response.data.result)
        setClientFound(true)
      } else {
        setClientFound(false)
      }
    } catch (error) {
      setClientFound(false)
    }
  }

  const getSessionById = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`
      )
      setSessionData(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getClientById(id ?? clientId)
    if (sessionId) {
      getSessionById()
    }
  }, [id, clientId, sessionId])

  const openQuery = () => {
    setQuery({})
    setModalTitle(`Log a Query`)
    setOpenQueryModal(true)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (openQueryModal) {
      return <LogQueryElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
  }

  const LogQueryElement = () => {
    const addQuery = async (e) => {
      e.preventDefault()
      try {
        const queryData = {
          client_id: id ?? clientId ?? '',
          user_id: auth.user.id,
          reported_date:
            e.target.reported_date.value ?? moment().format('YYYY-MM-DD'),
          outcome: e.target.outcome.value,
          action: e.target.action.value,
          session_id: sessionId ?? sessionData.id ?? '',
          claim_id: claimId ?? null,
        }

        if (sessionData?.start_time || e.target.session_start_time?.value) {
          queryData.session_start_time =
            e.target.session_start_time.value ?? sessionData.start_time
        }

        const response = await axios.post(`/clients/query`, queryData)
        if (!response.data.error) {
          createInfo(
            'success',
            `Query added for client ${
              clientData.client_relationship?.name ?? clientData.name
            }`
          )
        } else {
          createInfo('error', response.data.message)
        }
      } catch (error) {
        createInfo('error', 'Error adding query')
        console.log(error)
      }
      resetModal()
    }

    return (
      <form onSubmit={addQuery}>
        <div className="grid grid-cols-2 gap-2">
          <div className="mb-3">
            <label
              htmlFor="external_source"
              className="block font-medium text-gray-600 text-sm"
            >
              Client Reference <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="text"
                name="client_reference"
                id="client_reference"
                defaultValue={
                  clientData.client_relationship?.reference ??
                  clientData.reference
                }
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          {claimId && (
            <div className="mb-3">
              <label
                htmlFor="external_source"
                className="block font-medium text-gray-600 text-sm"
              >
                Claim Reference <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  required
                  type="text"
                  name="claim_reference"
                  id="claim_reference"
                  defaultValue={clientData.reference}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
          )}
          {sessionId && (
            <div className="mb-3">
              <label
                htmlFor="session_start_time"
                className="block font-medium text-gray-600 text-sm"
              >
                Session Start Time <span className="text-red-500">*</span>
              </label>
              <div className="mt-1 relative rounded-md">
                <input
                  type="datetime-local"
                  name="session_start_time"
                  id="session_start_time"
                  defaultValue={sessionData.start_time}
                  className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                />
              </div>
            </div>
          )}
          <div className="mb-3">
            <label
              htmlFor="reference"
              className="block font-medium text-gray-600 text-sm"
            >
              Reporter <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="text"
                name="reference"
                id="reference"
                defaultValue={auth.user.name}
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="external_source"
              className="block font-medium text-gray-600 text-sm"
            >
              Reported Date <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="date"
                name="reported_date"
                id="reported_date"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="external_source"
              className="block font-medium text-gray-600 text-sm"
            >
              Outcome <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <input
                required
                type="text"
                name="outcome"
                id="outcome"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="external_source"
              className="block font-medium text-gray-600 text-sm"
            >
              Action <span className="text-red-500">*</span>
            </label>
            <div className="mt-1 relative rounded-md">
              <textarea
                required
                type="text"
                name="action"
                id="action"
                className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              />
            </div>
          </div>
        </div>
        <div className="text-sm">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Query</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  return (
    <div className="relative flex min-h-screen bg-slate-100">
      {showModal && (
        <Modal
          title={modalTitle}
          body={<ModalBody />}
          show={resetModal}
          width={'650px'}
        />
      )}
      <LeftBar collapsed={collapsed} toggleSidebar={toggleSidebar} />
      <main
        className={
          collapsed ? 'mx-8 p-4 md:pl-0 w-full' : 'lg:ml-80 p-4 md:pl-0 w-full '
        }
      >
        <Header collapsed={collapsed} />
        <div className="mt-20">
          <Outlet />
        </div>
      </main>
      {collapsed && (
        <div className="absolute bottom-0 mb-4 ml-4">
          <button
            onClick={toggleSidebar}
            className="text-white p-2 rounded-md bg-slate-700 hover:bg-slate-600"
          >
            Open Sidebar
          </button>
        </div>
      )}
      {showLoader === true && <Loader />}
      {showInfo?.message && <InfoMessage />}
      {showUpdate && <AppUpdate />}
      {window.location.href.includes('/clients/') &&
        can('access notifications') && (
          <button
            type="button"
            onClick={openQuery}
            className=" fixed bottom-4 right-4 "
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="h-14 w-14 text-blue-500 rounded-full shadow-lg"
            />
          </button>
        )}
    </div>
  )
}

export default MainLayout
